"use strict";

import "regulus-oscar";
import "./components/filter-form";
import imagesLoaded from 'imagesloaded';
import Masonry from "masonry-layout/masonry";
import Cookies from "js-cookie";

$(function () {
    $('.slider').each(function (_, element) {
      const slider = $(element);
      const sliders = slider.find('.slider-container');

      slider.find('.previous').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: -275,
                behavior: 'smooth'
              });
          }
      });

      slider.find('.next').click(function () {
          for (let i = 0; i < sliders.length; i++) {
              sliders[i].scrollBy({
                top: 0,
                left: 275,
                behavior: 'smooth'
              });
          }
      });

    });
  });

imagesLoaded('.grid', function(){
    if($('.grid').length) {
        new Masonry( '.grid', {itemSelector: '.grid-item'});
    }
});

$(function(){
    $('.js-product-img-slide').hover(function(){
        var badge = $(this),
            pos = parseInt(badge.data('slide-to')),
            carousel = badge.closest('.carousel'),
            img = $(carousel.find('.carousel-item img')[pos]);
        // Lazy load the image, if it hasn't been loaded already
        if(!img.data('image-loaded')){
            img.attr('src', img.data('src'));
            img.attr('srcset', img.data('srcset'));
            img.data('image-loaded', 1);
        }
        carousel.carousel(pos);
    });
});

$(function() {
    function addMessage(tag, text) {
        var html = `<div class="alert alert-${ tag }" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    ${ text }
                    </div>`;
       $('#messages').append($(html));
    }

    $('.js-home-try-on-list-add').on('click', function() {
        const $button = $(this),
              productId = $button.data('product-id'),
              productTitle = $button.data('product-title'),
              url = $button.data('url'),
              listUrl = $button.data('list-url'),
              checkoutUrl = $button.data('checkout-url'),
              $homeTryOnListDiv = $('.home-try-on-list'),
              $totalNumOfProductsBadge = $homeTryOnListDiv.find('span.badge');
        $.ajax({
            url: url,
            method: 'POST',
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRFToken', Cookies.get('csrftoken'));
            },
            success: function(data, textStatus, jqXHR) {
                if (data.totalNumOfProducts > 0) {
                    $homeTryOnListDiv.removeClass('d-none');
                    $totalNumOfProductsBadge.html(data.totalNumOfProducts);
                } else {
                    $homeTryOnListDiv.addClass('d-none');
                }

                if (data.type === 'Success') {
                    addMessage('success',
                               `<strong>${ productTitle }</strong> has been added to your try-on basket.`);
                    addMessage('info',
                               `<p>Your try-on basket now has <strong>${ data.totalNumOfProducts }</strong> product${ data.totalNumOfProducts > 1 ? "s" : "" }</p>
                                <p>
                                <a href="${ listUrl }" class="btn btn-info">View home try-on basket</a>
                                <a href="${ checkoutUrl }" class="btn btn-info">Checkout now</a>
                                </p>`);
                } else {
                    addMessage(data.type === 'Warning' ? 'warning' : 'danger',
                               data.message);
                }
                $.each(data.messages, function(i, message) {
                    addMessage(message.tag, message.text);
                });
            },
            error: function(jqXHR, textStatus, errorThrown) {
                addMessage('danger',
                           errorThrown !== "" ? errorThrown : "Server error");
            },
        });
    });

    $('.js-home-try-on-list-remove').on('click', function homeTryOnListRemoveHandler() {
        const $button = $(this),
              productId = $button.data('product-id'),
              productTitle = $button.data('product-title'),
              url = $button.data('url'),
              $homeTryOnListDiv = $('.home-try-on-list'),
              $totalNumOfProductsBadge = $homeTryOnListDiv.find('span.badge');
        $.ajax({
            url: url,
            method: 'POST',
            beforeSend: function(jqXHR, settings) {
                jqXHR.setRequestHeader('X-CSRFToken', Cookies.get('csrftoken'));
            },
            success: function(data, textStatus, jqXHR) {
                $('.basket').replaceWith(data.list_content);
                // Reattach the event handler removed by "replaceWith()" above
                $('.js-home-try-on-list-remove').on('click', homeTryOnListRemoveHandler);

                if (data.totalNumOfProducts > 0) {
                    $homeTryOnListDiv.removeClass('d-none');
                    $totalNumOfProductsBadge.html(data.totalNumOfProducts);
                } else {
                    $homeTryOnListDiv.addClass('d-none');
                }

                if (data.type === 'Success') {
                    addMessage('success',
                               `<strong>${ productTitle }</strong> has been removed from your try-on basket.`);
                } else {
                    addMessage(data.type === 'Warning' ? 'warning' : 'danger',
                               data.message);
                }
                $.each(data.messages, function(i, message) {
                    addMessage(message.tag, message.text);
                });
            },
            error: function(jqXHR, textStatus, errorThrown) {
                addMessage('danger',
                           errorThrown !== "" ? errorThrown : "Server error");
            },
        });
    });
});

$(function() {
    $('.prescription-basket-add-form').each(function() {
        const $form = $(this),
              $quantityLabel = $form.find('div[data-step-name=quantity] label'),
              $quantitySelect = $form.find('select[name=quantity]'),
              $leftEyeCheckbox = $form.find('input[name=left_eye]'),
              $rightEyeCheckbox = $form.find('input[name=right_eye]');

        $quantityLabel.html($quantityLabel.html() + ' <span class="js-quantity-label-suffix"></span>');

        function setQuantityHints() {
            const $quantityLabelSuffixSpan = $form.find('.js-quantity-label-suffix');
            const $quantityStepQuantityHintSpan = $form.find('.js-quantity-step-quantity-hint');
            const quantityUnit = $quantityStepQuantityHintSpan.data('quantity-unit');
            const $lensPrescriptionStepQuantityHintSpan = $form.find('.js-lens-prescription-step-quantity-hint');
            const quantity = parseInt($quantitySelect.val());
            if ($leftEyeCheckbox.is(':checked') && $rightEyeCheckbox.is(':checked')) {
                $quantityLabelSuffixSpan.html('(per eye)');
                $quantityStepQuantityHintSpan.html(`${ quantity * 2 } ${ quantityUnit[1] } in total (you have the option of selecting a single eye in the next step)`);
                $lensPrescriptionStepQuantityHintSpan.html(`${ quantity * 2 } ${ quantityUnit[1] } in total (${ quantity } per eye)`);
            } else if ($leftEyeCheckbox.is(':checked') || $rightEyeCheckbox.is(':checked')) {
                $quantityLabelSuffixSpan.empty();
                $quantityStepQuantityHintSpan.html(`${ quantity } ${ quantity > 1 ? quantityUnit[1] : quantityUnit[0] } for a single eye (you have the option of selecting both eyes in the next step)`);
                $lensPrescriptionStepQuantityHintSpan.html(`${ quantity } ${ quantity > 1 ? quantityUnit[1] : quantityUnit[0] }`);
            } else {
                $quantityLabelSuffixSpan.empty();
                $quantityStepQuantityHintSpan.html('You can enter prescriptions for each eye in the next step');
                $lensPrescriptionStepQuantityHintSpan.html('Please select at least one eye');
            }
        }

        function enableOrDisableSelectsForEye($checkboxInput) {
            const selectSelector = `.${ $checkboxInput.parents('.control-label').data('select-parent-class') } select`;
            if ($checkboxInput[0].checked) {
                $form.find(selectSelector).prop('disabled', false);
            } else {
                $form.find(selectSelector).prop('disabled', true);
            }
        }

        $quantitySelect.on('change', function() {
            setQuantityHints();
        });

        $form.find('input[name=left_eye], input[name=right_eye]').on('change', function() {
            setQuantityHints();
            enableOrDisableSelectsForEye($(this));
        });

        $leftEyeCheckbox.trigger('change');
        $rightEyeCheckbox.trigger('change');
    });
});

$(function() {
    $('.prescription-basket-add-form').each(function() {
        let stepsMap = null,
            stepNames = [],
            previousStepName = null,
            nextStepName = null,
            labelsForRequiredFields = [],
            glassesPrescriptionFieldsRequiredAttrs = {};

        const $form = $(this),
              prescriptionStepsMap = $form.data('prescription-steps-map'),
              lensTypeRadioGroupInputsMap = $form.data('lens-type-radio-group-inputs-map'),
              $lensTypeUseRadioGroupDiv = $form.find('input[name=lens_type_use]').closest('div[data-toggle=buttons]'),
              $lensTypePropertiesRadioGroupDiv = $form.find('input[name=lens_type_properties]').closest('div[data-toggle=buttons]'),
              $lensTypeMaterialRadioGroupDiv = $form.find('input[name=lens_type_material]').closest('div[data-toggle=buttons]'),
              $lensTypeColorRadioGroupDiv = $form.find('input[name=lens_type_color]').closest('div[data-toggle=buttons]'),
              $stepHelpTextSpan = $form.find('#prescription-step-help-text'),
              $previousStepButton = $form.find('button.previous-step[data-previous-step-name]'),
              $nextStepButton = $form.find('button.next-step[data-next-step-name]'),
              $withPrescriptionOptionButton = $form.find('button.prescription-glasses-option[data-option-name=with_prescription]'),
              $withoutPrescriptionOptionButton = $form.find('button.prescription-glasses-option[data-option-name=without_prescription]'),
              $withoutPrescriptionOrLensTypeOptionButton = $form.find('button.prescription-glasses-option[data-option-name=without_prescription_or_lens_type]'),
              $prescriptionDataLink = $form.find('a.prescription-data'),
              $prescriptionUploadLink = $form.find('a.prescription-upload'),
              $dataSelects = $form.find($.map(['sphere', 'cylinder', 'axis', 'pup_dist', 'add'], function(name, i) {
                  return [`select[name=${ name }_0]`, `select[name=${ name }_1]`];
              }).join(', ')),
              $prescriptionFileInput = $form.find('input[name=prescription_file]'),
              $addToCartButtonDiv = $form.find('.prescription-add-to-cart-button'),

              setStepsMap = function(prescriptionGlassesStepsOptionName) {
                  if (typeof prescriptionGlassesStepsOptionName !== 'undefined') {
                      stepsMap = prescriptionStepsMap[prescriptionGlassesStepsOptionName];
                  } else {
                      stepsMap = prescriptionStepsMap;
                  }
                  stepNames.length = 0;
                  $.map(stepsMap, function(step, i) {
                      stepNames.push(step['name']);
                  });
              },

              generatePreviousStepName = function(currentStepName) {
                  const currentStepIndex = stepNames.indexOf(currentStepName),
                        previousStepIndex = currentStepIndex - 1;
                  if (previousStepIndex >= 0) {
                      previousStepName = stepsMap[previousStepIndex]['name'];
                      return;
                  }
                  previousStepName = null;
              },

              generateNextStepName = function(currentStepName) {
                  const currentStepIndex = stepNames.indexOf(currentStepName),
                        nextStepIndex = currentStepIndex + 1;
                  if (nextStepIndex < stepsMap.length) {
                      nextStepName = stepsMap[nextStepIndex]['name'];
                      return;
                  }
                  nextStepName = null;
              },

              areEyeCheckboxesChecked = function($stepDivs) {
                  if ($stepDivs.data('step-name') === 'lens_prescription') {
                      return $stepDivs.find('input[name=left_eye]:checked, input[name=right_eye]:checked').length > 0;
                  } else {
                      return true;
                  }
              },

              areRequiredSelectsInStepSelected = function($stepDivs) {
                  const $unselectedSelects = $stepDivs.find('select[required]:not([disabled])').filter(function() {
                      return $(this).val() === "";
                  });
                  return $unselectedSelects.length === 0
              },

              areSelectsInMultiWidgetValid = function($select) {
                  // The mainipulations here only apply for the
                  // "glasses_prescription" step
                  if ($select.closest('[data-step-name]').attr('data-step-name') !== 'glasses_prescription') {
                      return true;
                  }

                  const $multiWidgetDiv = $select.closest('div.multi-widget'),
                        $multiWidgetLabel = $multiWidgetDiv.parent().prev('label');

                  if ((labelsForRequiredFields.indexOf($multiWidgetLabel.attr('for')) !== -1) || ($multiWidgetDiv.length === 0)) {
                      // Do not validate selects that are required, or are not
                      // part of a multi widget
                      return true;
                  }

                  const selectIsSelected = $select.val() !== "",
                        $siblingSelects = $multiWidgetDiv.find('select').not($select);
                  let siblingSelectFound = false;
                  $siblingSelects.each(function() {
                      const $siblingSelect = $(this),
                            siblingSelectIsSelected = $siblingSelect.val() !== "";
                      if (selectIsSelected !== siblingSelectIsSelected) {
                          // Add "required" and "attention-needed" visual cues,
                          // and set the fields' "required" property
                          if (selectIsSelected) {
                              $multiWidgetLabel.addClass('required');
                          }
                          $select.prop('required', true);
                          $siblingSelects.prop('required', true);
                          $siblingSelects.addClass('border-danger');
                          $siblingSelect.focus();
                          siblingSelectFound = true;
                          // Break from the loop
                          return false;
                      }
                  });
                  if (siblingSelectFound) {
                      return false;
                  } else {
                      // Remove "required" and "attention-needed" visual cues,
                      // and unset the fields' "required" property
                      $multiWidgetLabel.removeClass('required');
                      $select.removeClass('border-danger');
                      $siblingSelects.removeClass('border-danger');
                      $select.prop('required', false);
                      $siblingSelects.prop('required', false);

                      return true;
                  }
              },

              areAxisSelectsValid = function($select) {
                  // The mainipulations here only apply for the
                  // "glasses_prescription" step
                  if ($select.closest('[data-step-name]').attr('data-step-name') !== 'glasses_prescription') {
                      return true;
                  }

                  const $cylinderSelects = $form.find('select[name=cylinder_0], select[name=cylinder_1]'),
                        $axisSelects = $form.find('select[name=axis_0], select[name=axis_1]'),
                        $axisSelectsLabel = $axisSelects.closest('[data-step-name]').find('label'),
                        $unselectedCylinderSelects = $cylinderSelects.filter(function() {
                            return $(this).val() === "";
                        }),
                        $unselectedAxisSelects = $axisSelects.filter(function() {
                            return $(this).val() === "";
                        });
                  if ($unselectedCylinderSelects.length === 0) {
                      // Cylinder selects are selected
                      $axisSelectsLabel.addClass('required');
                      if ($unselectedAxisSelects.length === 0) {
                          // Axis selects are selected
                          return true;
                      } else {
                          return false;
                      }
                  } else {
                      $axisSelectsLabel.removeClass('required');
                      return true;
                  }
              },

              areEyeInputsValid = function() {
                  // At least one must be checked
                  return $form.find('input[name=left_eye]:checked, input[name=right_eye]:checked').length > 0;
              },

              setGlassesPrescriptionDataFieldsRequiredAttr = function(restore) {
                  const fieldNames = ['sphere', 'cylinder', 'axis', 'pup_dist', 'add'];
                  $.each(fieldNames, function(i, fieldName) {
                      const $selects = $form.find(`select[name=${ fieldName }_0], select[name=${ fieldName }_1]`),
                            $selectsLabel = $selects.closest('[data-step-name]').find('label');
                      if (restore === true) {
                          // Retrieve the field's "required" attribute from the
                          // cache
                          const required = glassesPrescriptionFieldsRequiredAttrs['data'][fieldName];
                          if (required === 'required') {
                              $selects.attr('required', "required");
                              $selectsLabel.addClass('required');
                          } else {
                              $selects.removeAttr('required');
                              $selects.removeClass('border-danger');
                              $selectsLabel.removeClass('required');
                          }
                      } else {
                          $selects.removeAttr('required');
                          $selects.removeClass('border-danger');
                          $selectsLabel.removeClass('required');
                      }
                  });
              },

              setGlassesPrescriptionUploadFieldRequiredAttr = function(restore) {
                  const fieldName = 'prescription_file',
                        $input = $form.find(`input[name=${ fieldName }]`),
                        $inputLabel = $input.closest('[data-step-name]').find('label');
                  if (restore === true) {
                      // Retrieve the field's "required" atribute from the cache
                      const required = glassesPrescriptionFieldsRequiredAttrs['upload'][fieldName];
                      if (required === 'required') {
                          $input.attr('required', "required");
                          $inputLabel.addClass('required');
                      } else {
                          $input.removeAttr('required');
                          $inputLabel.removeClass('required');
                      }
                  } else {
                      $input.removeAttr('required');
                      $inputLabel.removeClass('required');
                  }
              },

              removeLensTypeFieldsRequiredAttr = function(restore) {
                  const fieldNames = ['lens_type_use', 'lens_type_properties', 'lens_type_material', 'lens_type_color'];
                  $.each(fieldNames, function(i, fieldName) {
                      const $inputs = $form.find(`input[name=${ fieldName }]`);
                      $inputs.removeAttr('required');
                  });
              },

              areRequiredRadioGroupsInStepChecked = function($stepDivs) {
                  const $uncheckedRadioGroups = $stepDivs.find('div[data-toggle=buttons]').has('input[required]').filter(function() {
                      return $(this).find('label.active').length === 0;
                  });
                  return $uncheckedRadioGroups.length === 0
              },

              restrictLensTypeNextRadioGroupInputs = function($input) {
                  const inputName = $input.attr('name');
                  if (inputName === 'lens_type_use') {
                      const useInputValue = $input.val(),
                            propertiesRadioGroupInputsMap = lensTypeRadioGroupInputsMap[useInputValue];

                      $lensTypePropertiesRadioGroupDiv.find('label').addClass('d-none').removeClass('active').each(function() {
                          const $button = $(this),
                                inputValue = $button.find('input').val();
                          if (inputValue in propertiesRadioGroupInputsMap) {
                              $button.removeClass('d-none');
                          }
                      });

                      $lensTypePropertiesRadioGroupDiv.find('label + span').removeClass('d-block').addClass('d-none').each(function() {
                          const $helpTextSpan = $(this),
                                inputValue = $helpTextSpan.prev('label').find('input').val();
                          if (inputValue in propertiesRadioGroupInputsMap) {
                              $helpTextSpan.removeClass('d-none').addClass('d-block');
                          }
                      });

                  } else if (inputName === 'lens_type_properties') {
                      const useInputValue = $lensTypeUseRadioGroupDiv.find('label.active input').val(),
                            propertiesInputValue = $input.val(),
                            materialRadioGroupInputsMap = lensTypeRadioGroupInputsMap[useInputValue][propertiesInputValue];

                      $lensTypeMaterialRadioGroupDiv.find('label').addClass('d-none').removeClass('active').each(function() {
                          const $button = $(this),
                                $labelSpan = $button.find('span'),
                                inputValue = $button.find('input').val();

                          if (inputValue in materialRadioGroupInputsMap) {
                              let labelSpanHtml = $labelSpan.html();
                              // Remove the previous price from the label span HTML
                              if (labelSpanHtml.indexOf(' + ') !== -1) {
                                  labelSpanHtml = labelSpanHtml.substring(0, labelSpanHtml.indexOf(' + '));
                              }
                              // Add the new price to the label span HTML
                              if (materialRadioGroupInputsMap[inputValue]['price'] !== null) {
                                  $labelSpan.html(labelSpanHtml + ` + ${ materialRadioGroupInputsMap[inputValue]['price'] }`);
                              }
                              $button.removeClass('d-none');
                          }
                      });

                      $lensTypeMaterialRadioGroupDiv.find('label + span').removeClass('d-block').addClass('d-none').each(function() {
                          const $helpTextSpan = $(this),
                                inputValue = $helpTextSpan.prev('label').find('input').val();

                          if (inputValue in materialRadioGroupInputsMap) {
                              // Add the help text to the help-text span HTML
                              if ('helpText' in materialRadioGroupInputsMap[inputValue]) {
                                  $helpTextSpan.html(materialRadioGroupInputsMap[inputValue]['helpText']);
                                  $helpTextSpan.removeClass('d-none').addClass('d-block');
                              }
                          }
                      });

                  } else if (inputName === 'lens_type_material') {
                      const useInputValue = $lensTypeUseRadioGroupDiv.find('label.active input').val(),
                            propertiesInputValue = $lensTypePropertiesRadioGroupDiv.find('label.active input').val(),
                            materialInputValue = $input.val(),
                            colorRadioGroupInputsMap = lensTypeRadioGroupInputsMap[useInputValue][propertiesInputValue][materialInputValue];

                      $lensTypeColorRadioGroupDiv.find('label').addClass('d-none').removeClass('active').each(function() {
                          const $button = $(this),
                                inputValue = $button.find('input').val();
                          if (('colors' in colorRadioGroupInputsMap) && (colorRadioGroupInputsMap['colors'].indexOf(inputValue) !== -1)) {
                              $button.removeClass('d-none');
                          }
                      });

                  }
              },

              displayStepDivs = function(stepName) {
                  // TODO: Rework the handling of the "lens_type_color"
                  //       field/step to make it more generic, if we in future
                  //       have another field that can be blank, or if this can
                  //       be a step other than the last one
                  const $stepDivs = $form.find(`div[data-step-name=${ stepName }]`),
                        hasRadioButtonsForLensTypeColorStep = $lensTypeColorRadioGroupDiv.find('label:not(.d-none)').length > 0;

                  if ((stepName === 'lens_type_color') && !hasRadioButtonsForLensTypeColorStep) {
                      const stepName = previousStepName;
                      generatePreviousStepName(stepName);
                      generateNextStepName(stepName);
                      displayStepDivs(stepName);
                      return;
                  }

                  if (stepName === 'glasses_prescription') {
                      const allRequiredDataSelectsAreSelected = $.map(['sphere', 'pup_dist'], function(name, i) {
                                if (($form.find(`select[name=${ name }_0]`).val() === "") || ($form.find(`select[name=${ name }_1]`).val() === "")) {
                                    return true;
                                } else {
                                    return null;
                                }
                            }).length === 0,
                            prescriptionFileInputIsSelected = $prescriptionFileInput.val() !== "";

                      if ((allRequiredDataSelectsAreSelected) || (!allRequiredDataSelectsAreSelected && !prescriptionFileInputIsSelected)) {
                          // Hide the upload field (after removing its
                          // "required" attribute, and clearing its value)
                          setGlassesPrescriptionUploadFieldRequiredAttr();
                          $prescriptionFileInput.val("").closest('div[data-step-name=glasses_prescription]').addClass('d-none');

                          // Show the eye labels and data fields
                          $form.find('div.left-eye:not(:has("select")), div.right-eye:not(:has("select"))').closest('div[data-step-name=glasses_prescription]').removeClass('d-none');
                          $dataSelects.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

                          // Hide data-fields link, and show the upload-field
                          // link
                          $prescriptionDataLink.closest('div[data-step-name=glasses_prescription]').addClass('d-none');
                          $prescriptionUploadLink.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');
                      } else if (prescriptionFileInputIsSelected) {
                          // Hide the eye labels and data fields (after removing
                          // their "required" attribute, and clearing their
                          // value)
                          setGlassesPrescriptionDataFieldsRequiredAttr();
                          $dataSelects.val("");
                          $form.find('div.left-eye:not(:has("select")), div.right-eye:not(:has("select"))').closest('div[data-step-name=glasses_prescription]').addClass('d-none');
                          $dataSelects.closest('div[data-step-name=glasses_prescription]').addClass('d-none');

                          // Show the upload field
                          $prescriptionFileInput.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

                          // Hide the upload-field link, and show the
                          // data-fields link
                          $prescriptionUploadLink.closest('div[data-step-name=glasses_prescription]').addClass('d-none');
                          $prescriptionDataLink.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');
                      }
                  } else {
                      $stepDivs.removeClass('d-none');
                  }

                  $stepHelpTextSpan.html(stepsMap[stepNames.indexOf(stepName)]['helpText']);
                  $stepHelpTextSpan.removeClass('d-none');

                  if (previousStepName !== null) {
                      $previousStepButton.data('previous-step-name', previousStepName);
                      $previousStepButton.removeClass('d-none');
                  } else {
                      $previousStepButton.addClass('d-none');
                  }

                  if (areEyeCheckboxesChecked($stepDivs) && areRequiredSelectsInStepSelected($stepDivs) && areRequiredRadioGroupsInStepChecked($stepDivs)) {
                      if ($.isPlainObject(prescriptionStepsMap) && (stepName == 'quantity')) {
                          // For prescription glasses, the "quantity" step has
                          // special next-step buttons, that initiate flows for
                          // purchasing with or without a prescription
                          $nextStepButton.addClass('d-none');
                          // The add-to-cart button may have been displayed
                          // because we could have moved back from the final
                          // step
                          $addToCartButtonDiv.addClass('d-none');
                      } else if (stepName === 'lens_type_color') {
                          const $uncheckedRadioGroups = $stepDivs.find('div[data-toggle=buttons]').has('input').filter(function() {
                              return $(this).find('label.active').length === 0;
                          });

                          if ($uncheckedRadioGroups.length === 0) {
                              $nextStepButton.addClass('d-none');
                              $addToCartButtonDiv.removeClass('d-none');
                          } else {
                              $nextStepButton.addClass('d-none');
                              $addToCartButtonDiv.addClass('d-none');
                          }
                      } else if ((nextStepName !== null) && ((nextStepName !== 'lens_type_color') || ((nextStepName === 'lens_type_color') && hasRadioButtonsForLensTypeColorStep))) {
                          $nextStepButton.find('span').html(stepsMap[stepNames.indexOf(stepName)]['nextStepButtonText']);
                          $nextStepButton.data('next-step-name', nextStepName);
                          $nextStepButton.removeClass('d-none');
                          // The add-to-cart button may have been displayed
                          // because we could have moved back from the final
                          // step
                          $addToCartButtonDiv.addClass('d-none');
                      } else {
                          $nextStepButton.addClass('d-none');
                          $addToCartButtonDiv.removeClass('d-none');
                      }
                  } else {
                      $nextStepButton.addClass('d-none');
                      // We do not need to hide the add-to-cart button here,
                      // because we could not have moved back from the final
                      // step (we could not have been able to proceed there in
                      // the first place, if not all selects had been selected),
                      // where it may have been displayed
                  }
              };

        $form.find('select, input[name=left_eye], input[name=right_eye]').not('select[name=quantity]').on('change', function() {
            const $el = $(this),
                  stepName = $el.closest('div[data-step-name]').data('step-name'),
                  $stepDivs = $form.find(`div[data-step-name=${ stepName }]`);

            if (((this.tagName === 'SELECT') && (!areSelectsInMultiWidgetValid($el) || !areAxisSelectsValid($el))) || ((this.tagName === 'INPUT') && !areEyeInputsValid())) {
                $nextStepButton.addClass('d-none');
                $addToCartButtonDiv.addClass('d-none');
                return;
            }

            if (areRequiredSelectsInStepSelected($stepDivs)) {
                if (nextStepName !== null) {
                    if ($stepDivs.find('select').length > 1) {
                        // The step has multiple selects
                        $nextStepButton.find('span').html(stepsMap[stepNames.indexOf(stepName)]['nextStepButtonText']);
                        $nextStepButton.data('next-step-name', nextStepName);
                        $nextStepButton.removeClass('d-none');
                    } else {
                        $stepDivs.addClass('d-none');
                        const stepName = nextStepName;
                        generatePreviousStepName(stepName);
                        generateNextStepName(stepName);
                        displayStepDivs(stepName);
                    }
                } else {
                    $addToCartButtonDiv.removeClass('d-none');
                }
            } else {
                $nextStepButton.addClass('d-none');
                $addToCartButtonDiv.addClass('d-none');
            }
        });

        $prescriptionFileInput.on('change', function() {
            const $input = $(this),
                  stepName = $input.closest('div[data-step-name]').data('step-name'),
                  $stepDivs = $form.find(`div[data-step-name=${ stepName }]`);

            if (($input.val() !== "") && (stepName === 'glasses_prescription')) {
                // Move to the next step
                $stepDivs.addClass('d-none');
                const stepName = nextStepName;
                generatePreviousStepName(stepName);
                generateNextStepName(stepName);
                displayStepDivs(stepName);
            }
        });

        $form.find('label.btn input').on('click', function() {
            const $input = $(this),
                  $button = $input.closest('label'),
                  $stepDiv = $button.closest('div[data-step-name]'),
                  stepName = $stepDiv.data('step-name'),
                  $stepDivs = $form.find(`div[data-step-name=${ stepName }]`);

            if (stepsMap[stepNames.indexOf(stepName)]['isLensType'] === true) {
                restrictLensTypeNextRadioGroupInputs($input);
            }

            // If the step has multiple radio groups, check if any of the other
            // required radio groups has not been checked
            const $otherUncheckedRequiredRadioGroupsInStep = $stepDivs.not($stepDiv).find('div[data-toggle=buttons]').has('input[required]').filter(function() {
                return $(this).find('label.active').length === 0;
            });

            if ($otherUncheckedRequiredRadioGroupsInStep.length === 0) {
                // The required radio groups in this step have been checked
                if (nextStepName !== null) {
                    if ($stepDivs.find('div[data-toggle=buttons]').length > 1) {
                        // The step has multiple radio groups
                        $nextStepButton.find('span').html(stepsMap[stepNames.indexOf(stepName)]['nextStepButtonText']);
                        $nextStepButton.data('next-step-name', nextStepName);
                        $nextStepButton.removeClass('d-none');
                    } else {
                        $stepDivs.addClass('d-none');
                        // Stay clear of "stepName" and "nextStepName" variables
                        const theStepName = nextStepName;
                        generatePreviousStepName(theStepName);
                        generateNextStepName(theStepName);
                        displayStepDivs(theStepName);
                    }
                } else {
                    $addToCartButtonDiv.removeClass('d-none');
                }
            } else {
                $nextStepButton.addClass('d-none');
                $addToCartButtonDiv.addClass('d-none');
            }
        });

        $previousStepButton.on('click', function(e) {
            e.preventDefault();
            // Hide the currently-visible step
            $form.find('div[data-step-name]:not(.d-none)').addClass('d-none');
            const stepName = $(this).data('previous-step-name');
            generatePreviousStepName(stepName);
            generateNextStepName(stepName);
            displayStepDivs(stepName);
        });

        $nextStepButton.on('click', function(e) {
            e.preventDefault();
            // Hide the currently-visible step
            $form.find('div[data-step-name]:not(.d-none)').addClass('d-none');
            const stepName = $(this).data('next-step-name');
            generatePreviousStepName(stepName);
            generateNextStepName(stepName);
            displayStepDivs(stepName);
        });

        $withPrescriptionOptionButton.on('click', function(e) {
            e.preventDefault();
            // Hide the currently-visible step
            $form.find('div[data-step-name]:not(.d-none)').addClass('d-none');
            // Restore the "required" attribute of the "glasses_prescription"
            // step's fields
            setGlassesPrescriptionUploadFieldRequiredAttr(true);
            setGlassesPrescriptionDataFieldsRequiredAttr(true);
            // Hide the "lens_type_use" step's "NONP" option (and its help
            // text), as it is not valid for purchases with prescriptions
            // Also uncheck it, as it may have been checked before (via the
            // "without-prescription" option button)
            $form.find('input[name=lens_type_use][value=NONP]').closest('label').addClass('d-none').removeClass('active').next('span').each(function() {
                const $helpTextSpan = $(this);
                if ($helpTextSpan.text().trim().length !== 0) {
                    $helpTextSpan.removeClass('d-block').addClass('d-none');
                }
            });
            // Unhide the "lens_type_use" step's options (and their help text),
            // apart from the "NONP" option, as they may have been hidden before
            // (via the "without-prescription" option button)
            $form.find('input[name=lens_type_use][value!=NONP]').closest('label').removeClass('d-none').next('span').each(function() {
                const $helpTextSpan = $(this);
                if ($helpTextSpan.text().trim().length !== 0) {
                    $helpTextSpan.removeClass('d-none').addClass('d-block');
                }
            });
            // Go to the "glasses_prescription" step
            const optionName = $(this).data('option-name'),
                  stepName = "glasses_prescription";
            setStepsMap(optionName);
            generatePreviousStepName(stepName);
            generateNextStepName(stepName);
            displayStepDivs(stepName);
        });

        $withoutPrescriptionOptionButton.on('click', function(e) {
            e.preventDefault();
            // Hide the currently-visible step
            $form.find('div[data-step-name]:not(.d-none)').addClass('d-none');
            // Remove the "required" attribute of the "glasses_prescription"
            // step's fields
            setGlassesPrescriptionUploadFieldRequiredAttr();
            setGlassesPrescriptionDataFieldsRequiredAttr();
            // Hide the "lens_type_lens" step's options (and their help text),
            // apart from the "NONP" option, as they are not valid for purchases
            // without prescriptions
            $form.find('input[name=lens_type_use][value!=NONP]').closest('label').addClass('d-none').next('span').each(function() {
                const $helpTextSpan = $(this);
                if ($helpTextSpan.text().trim().length !== 0) {
                    $helpTextSpan.removeClass('d-block').addClass('d-none');
                }
            });
            // Unhide the "lens_type_lens" step's "NONP" option (and its help
            // text), as it may have been hidden before (via the
            // "with-prescription" option button)
            $form.find('input[name=lens_type_use][value=NONP]').closest('label').removeClass('d-none').next('span').each(function() {
                const $helpTextSpan = $(this);
                if ($helpTextSpan.text().trim().length !== 0) {
                    $helpTextSpan.removeClass('d-none').addClass('d-block');
                }
            });
            // Trigger a click on the "lens_type_use" step's "NONP" option, to
            // skip to the "lens_type_properties" step
            const optionName = $(this).data('option-name');
            setStepsMap(optionName);
            nextStepName = "lens_type_properties";
            $form.find('input[name=lens_type_use][value=NONP]').click().closest('label').addClass('active');
        });

        $withoutPrescriptionOrLensTypeOptionButton.on('click', function(e) {
            e.preventDefault();
            // Remove the "required" attribute of the "glasses_prescription"
            // step's fields
            setGlassesPrescriptionDataFieldsRequiredAttr();
            setGlassesPrescriptionUploadFieldRequiredAttr();
            // Clear the value of the "glasses_prescription" step's "sphere"
            // field (it has an initial value)
            $dataSelects.val("");
            // Remove the "required" attribute of the lens-type steps' fields
            removeLensTypeFieldsRequiredAttr();
            // Trigger a click on the add-to-cart button
            $addToCartButtonDiv.find('button').click();
        });

        $prescriptionDataLink.on('click', function(e) {
            e.preventDefault();
            // Hide the upload field (after removing its "required" attribute,
            // and clearing its value)
            setGlassesPrescriptionUploadFieldRequiredAttr();
            $prescriptionFileInput.val("").closest('div[data-step-name=glasses_prescription]').addClass('d-none');

            // Show the eye labels and data fields (after restoring their
            // "required" attribute)
            setGlassesPrescriptionDataFieldsRequiredAttr(true);
            $form.find('div.left-eye:not(:has("select")), div.right-eye:not(:has("select"))').closest('div[data-step-name=glasses_prescription]').removeClass('d-none');
            $dataSelects.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

            // Hide this link, and show the upload-field link
            $(this).closest('div[data-step-name=glasses_prescription]').addClass('d-none');
            $prescriptionUploadLink.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

            // The next-step button may have been displayed because the
            // "required" upload file-input field could previously have been
            // selected
            $nextStepButton.addClass('d-none');
        });

        $prescriptionUploadLink.on('click', function(e) {
            e.preventDefault();
            // Hide the eye labels and data fields (after removing their
            // "required" attribute, and clearing their value)
            setGlassesPrescriptionDataFieldsRequiredAttr();
            $dataSelects.val("");
            $form.find('div.left-eye:not(:has("select")), div.right-eye:not(:has("select"))').closest('div[data-step-name=glasses_prescription]').addClass('d-none');
            $dataSelects.closest('div[data-step-name=glasses_prescription]').addClass('d-none');

            // Show the upload field (after restoring its "required" attribute)
            setGlassesPrescriptionUploadFieldRequiredAttr(true);
            $prescriptionFileInput.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

            // Hide this link, and show the data-fields link
            $(this).closest('div[data-step-name=glasses_prescription]').addClass('d-none');
            $prescriptionDataLink.closest('div[data-step-name=glasses_prescription]').removeClass('d-none');

            // The next-step button may have been displayed because the
            // "required" data select fields could previously have been selected
            $nextStepButton.addClass('d-none');
        });

        if ($.isPlainObject(prescriptionStepsMap)) {
            // There are different steps for prescription glasses, depending on
            // whether one is purchasing with or without a prescription
            //
            // The steps maps set here are used to:
            // - populate the "data-next-step-name" attribute for each of the
            //   option buttons
            // - provide the first step name (which is shared by both
            //   alternatives), to be used to display the first step, further
            //   below.
            //
            // A new steps map is set when the customer chooses whether to
            // purchase with or without a prescription, later
            for (var optionName in prescriptionStepsMap) {
                setStepsMap(optionName);
                const $prescriptionGlassesStepsOptionButton = $form.find(`btn[data-option-name=${ optionName }]`),
                      stepName = $prescriptionGlassesStepsOptionButton.closest('div[data-step-name]').data('step-name');
                generateNextStepName(stepName);
                $withPrescriptionOptionButton.data('next-step-name', nextStepName);
            }
        } else {
            setStepsMap();
        }

        $form.find('label.required[for]').each(function() {
            labelsForRequiredFields.push($(this).attr('for'));
        });

        // Store the "glasses_prescription" step's data fields' "required"
        // attribute value in a cache
        glassesPrescriptionFieldsRequiredAttrs['data'] = {};
        const fieldNames = ['sphere', 'cylinder', 'axis', 'pup_dist', 'add'];
        $.each(fieldNames, function(i, fieldName) {
            const required = $form.find(`select[name=${ fieldName }_0]`).attr('required');
            glassesPrescriptionFieldsRequiredAttrs['data'][fieldName] = required === undefined ? null : required;
        });
        // Store the "glasses_prescription" step's upload field's "required"
        // attribute value in a cache
        glassesPrescriptionFieldsRequiredAttrs['upload'] = {};
        const fieldName = 'prescription_file',
              required = $form.find(`input[name=${ fieldName }]`).attr('required');
        glassesPrescriptionFieldsRequiredAttrs['upload'][fieldName] = required === undefined ? null : required;

        // Add "required" visual cue for the "lens_type_color" field, as the
        // field is actually not required (and is hidden in these cases)
        $form.find('div[data-step-name=lens_type_color] > label').addClass('required');

        // First step
        const stepName = stepNames[0];
        generateNextStepName(stepName);
        displayStepDivs(stepName);
    });
});
